@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "FSLight";
  font-style: normal;
  font-weight: 400;

  src: local("Open Sans"), local("OpenSans"),
    /* Modern Browsers */ url("./assets/fonts/Montserrat-Arabic-ExtraLight.otf")
      format("truetype");
}
@font-face {
  font-family: "FLight";
  font-style: normal;
  font-weight: 400;

  src: local("Open Sans"), local("OpenSans"),
    /* Modern Browsers */ url("./assets/fonts/Montserrat-Arabic-Light.otf")
      format("truetype");
}
@font-face {
  font-family: "FMed";
  font-style: normal;
  font-weight: 400;

  src: local("Open Sans"), local("OpenSans"),
    /* Modern Browsers */ url("./assets/fonts/Montserrat-Arabic-Medium.otf")
      format("truetype");
}
@font-face {
  font-family: "FBold";
  font-style: normal;
  font-weight: 400;

  src: local("Open Sans"), local("OpenSans"),
    /* Modern Browsers */ url("./assets/fonts/Montserrat-Arabic-Bold.otf")
      format("truetype");
}

body {
  font-family: "FuturaLight";
}
